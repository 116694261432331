// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import "jquery"
import "js-cookie"
import "chart.js/dist/Chart.bundle.min"
import "bootstrap"
import "kramed"
import "mailcheck"
import "../src/number_to_money"

window.$ = function(n) { return document.querySelector(n); }
window.$$ = function(n) { return document.querySelectorAll(n); }

Rails.start()
ActiveStorage.start()

// window.jQuery = jQuery
// window.$ = $
window.Cookies = Cookies
window.bootstrap = bootstrap
window.kramed = kramed
window.Mailcheck = Mailcheck

//import "../src/jquery.calculation.min"
import "../src/cookiedata"

window.ChartPalette = [
    '#1395BA', '#117899', '#0F5B78', '#0D3C55', '#C02E1D', '#D94E1F',
    '#F16C20', '#EF8B2C', '#ECAA38', '#EBC844', '#A2B86C', '#5CA793'
];
