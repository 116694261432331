Number.prototype.toMoney = function (decimalPlaces, decimalMark, thousandsSeparator) {
  let parts, money;
  this._decimalPlaces = 0;
  this._decimalMark = '.';
  this._thousandSeparator = ',';
  if (!isNaN(Math.abs(decimalPlaces))) this._decimalPlaces = decimalPlaces;
  if (undefined !== decimalMark) this._decimalMark = decimalMark;
  if (undefined !== thousandsSeparator) this._thousandSeparator = thousandsSeparator;
  parts = Math.abs(this).toFixed(this._decimalPlaces).split('.');
  money = '';
  if (0 > self) money += '-';
  money += parts[0].toString().split(/(?=(?:...)*$)/).join(this._thousandSeparator);
  if (this._decimalPlaces > 0) money += this._decimalMark + parts[1].toString();
  return money;
};
